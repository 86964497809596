<template>
  <KeepAlive>
    <b-card-code>
      <validation-observer ref="simpleRules">
        <b-form style="" @submit.prevent>
          <b-row cols="1" cols-sm="1" cols-xsm="1" cols-md="1" cols-lg="2">
            <!--  price -->
            <b-col>
              <b-form-group label="المبلغ" label-for="v-price">
                <validation-provider #default="{ errors }" name="المبلغ" rules="required">
                  <b-form-input
                    id="v-price"
                    v-model.number="expense.price"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder=" المبلغ بالجنيه"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="التاريخ" label-for="v-date">
                <validation-provider #default="{ errors }" name="التاريخ" rules="required">
                  <b-form-datepicker
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="ar"
                    v-model="expense.date"
                    id="datepicker-buttons"
                    placeholder="التاريخ "
                    :state="errors.length > 0 ? false : null"
                    today-button
                    reset-button
                    close-button
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="التفاصيل" label-for="v-details">
                <b-form-input id="v-details" v-model="expense.details" placeholder="التفاصيل" />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="الملف" label-for="v-file">
                <b-form-file id="v-file" accept="image/*" v-model="selectedFile"></b-form-file>
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                v-if="editExpense"
                :disabled="isValidating"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="validationForm"
              >
                تعديل
              </b-button>
              <b-button
                v-else
                :disabled="isValidating"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="validationForm"
              >
                إضافه
              </b-button>
              <b-button variant="primary" style="margin-right: 15px" @click="this.back"> رجوع </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
  </KeepAlive>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormRadio,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormFile
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormCheckboxGroup,
    BFormRadio,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormDatepicker,
    BFormFile
  },

  directives: {
    Ripple
  },
  props: ['id'],
  data() {
    return {
      isValidating: false,
      selectedFile: null,
      // ? Default locale for VeeValidate is 'en'
      locale: 'ar',
      // for validation
      required,
      // price: null,
      // date: null,
      // details: '',
      editExpense: false,
      expense: {
        agentId: '',
        price: '',
        date: '',
        details: ''
      }
    }
  },
  created() {
    if (this.$route.params.id) {
      this.editExpense = true
      this.GetExpense()
    }
  },
  mounted() {
    // switch to arabic in validation
    localize(this.locale)
  },
  methods: {
    async GetExpense() {
      try {
        const response = await this.$store.dispatch('GetExpense', this.$route.params.id)
        if ((response.status = 200)) {
          this.expense = response.data
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },

    async validationForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        this.isValidating = true

        if (success && !this.editExpense) {
          // eslint-disable-next-line
          await this.AddExpense()
        } else if (success && this.editExpense) {
          await this.EditExpense()
        }
        this.isValidating = false
      })
    },
    async AddExpense() {
      let userId = this.$store.getters.ExtractAcessToken.userId

      const formData = new FormData()
      formData.append('AgentId', userId)
      formData.append('price', this.expense.price)
      formData.append('date', this.expense.date)
      formData.append('details', this.expense.details)
      formData.append('file', this.selectedFile)

      try {
        const response = await this.$store.dispatch('CreateExpense', formData)
        if (response.status === 200) {
          this.$router.push({ name: 'expenses' })
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },

    async EditExpense() {
      const formData = new FormData()
      formData.append('id', this.$route.params.id)
      formData.append('AgentId', this.expense.agentId)
      formData.append('price', this.expense.price)
      formData.append('date', this.expense.date)
      formData.append('details', this.expense.details)
      formData.append('file', this.selectedFile)

      try {
        const response = await this.$store.dispatch('UpdateExpense', formData)
        if (response.status === 200) {
          this.$router.push({ name: 'expenses' })
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    back() {
      this.$router.push({
        name: 'expenses'
      })
    }
  }
}
</script>
<style scoped>
::v-deep .dropdown-menu {
  width: 270px;
}

::v-deep .vs__dropdown-toggle {
  padding: 0.5rem;
}
::v-deep [dir] .vs__dropdown-toggle {
  padding: 0 0 4px;
  background: none;
  border-radius: 4px;
  padding: 0.4rem;
}
</style>
